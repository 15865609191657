import {
  FETCH_COUPONS_LIST,
  SAVE_COUPONS_LIST,
  VERIFY_COUPON,
  FETCH_PAYMENT_OFFERS_LIST,
  SAVE_PAYMENT_OFFERS_LIST,
  VERIFY_PAYMENT_OFFER,
  FETCH_EVALUATED_COUPONS,
} from '../actionTypes';

export const getCouponsList = data => {
  return {
    type: FETCH_COUPONS_LIST,
    data,
  };
};

export const saveCouponsList = data => {
  return {
    type: SAVE_COUPONS_LIST,
    data,
  };
};

export const getPaymentOffersList = data => {
  return {
    type: FETCH_PAYMENT_OFFERS_LIST,
    data,
  };
};

export const savePaymentOffersList = data => {
  return {
    type: SAVE_PAYMENT_OFFERS_LIST,
    data,
  };
};

export const verifyCoupon = (data, callback) => {
  return {
    type: VERIFY_COUPON,
    data,
    callback,
  };
};

export const verifyPaymentOffer = (data, callback) => {
  return {
    type: VERIFY_PAYMENT_OFFER,
    data,
    callback,
  };
};

export const getEvaluatedCouponList = data => {
  return {
    type: FETCH_EVALUATED_COUPONS,
    data,
  };
};
