import { put, takeEvery } from 'redux-saga/effects';
import { toastNotifyError, toastNotifySuccess } from './../../components/Common/Toast';
import { setAuthToken } from '@/utils/setAuthToken';
import {
  saveAddressList,
  saveUserDetails,
  savePhone,
  fetchAddressList,
  setCart,
  updateCustomerLead,
  authenticateUser,
  pushEventsLog,
  saveEmail,
} from './../actions';

import { saveSelectedAddress } from './../../redux/actions';
import { setLogin, toggleVerifyOtp, truecallerAbsent } from '../actions/user';

import {
  GENERATE_OTP,
  VERIFY_OTP,
  AUTHENTICATE_USER,
  FETCH_ADDRESS_LIST,
  SAVE_ADDRESS,
  AUTH_TRUECALLER_DETAILS,
  GENERATE_OTP_FOR_EMAIL,
  VERIFY_OTP_FOR_EMAIL,
  LOGIN_WITH_GMAIL,
} from './../actionTypes';

import { ENDPOINT } from './../../config/endpoints';
import apiCall from './../../services/api';
import store from './../store';
import { PATH, getRoute } from './../../utils/routes';
import { getLeadId, getLeadData } from '@/utils/localStorageFunctions';
import { setModifiedDeliveryInfo } from '@/utils/setModifiedDeliveryInfo';
// Generate OTP
function* generateOTP(actions) {
  try {
    const otpResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.AUTH.generateOTP(actions.data, actions.queryParams),
      parseToJson: true,
    });
    if (otpResponse && otpResponse.data.status) {
      const storeData = store.getState().storeReducer.store;
      if (actions.mode === 'web') {
        toastNotifySuccess('OTP sent Successfully');
        actions.callback && actions.callback(true);
      } else {
        actions.router.push({
          pathname: getRoute(PATH.VERIFY_OTP, storeData.store_info.domain),
          query: { phone: actions.data },
        });
      }
    }
  } catch (err) {
    if (err.status === 401) actions.callback && actions.callback(false);
    console.log(err);
  }
}

// Verify OTP
function* verifyOTP(actions) {
  const storeData = store.getState().storeReducer.store;
  const userData = store.getState().userReducer.data;
  const storeId = storeData?.store_id;

  try {
    const apiConfig = {
      method: actions.useOldOTPService ? 'GET' : 'POST',
      url: actions.useOldOTPService
        ? ENDPOINT.AUTH.verifyOTP(actions.data.phone, actions.data.otp, storeId)
        : ENDPOINT.AUTH.verifyOTPLess(),
      parseToJson: true,
    };
    if (!actions.useOldOTPService) {
      apiConfig.data = { token: actions.data.token, store_id: storeId };
      if (actions.data.source) {
        apiConfig.data.source = actions.data.source;
      }
    }
    const verifyResponse = yield apiCall(apiConfig);
    if (verifyResponse && verifyResponse.data.status) {
      yield put(pushEventsLog(actions.data.clevertapEventData));
      if (actions?.isLoginAttempt) {
        const cartData = store.getState().cartReducer;
        yield put(saveSelectedAddress({}));
        yield put(savePhone(actions.data.phone));
        yield put(saveEmail(actions.data.email));
        localStorage?.setItem('auth_token', verifyResponse?.data?.data?.auth_token);

        yield put(
          saveUserDetails({
            fullname: '',
            email: actions.data.email,
            phone: actions.data.phone,
            user_id: verifyResponse?.data?.data?.user_id,
          })
        );
        yield put(setLogin());
        yield put(toggleVerifyOtp());
        if (cartData?.cart_id && cartData?.store_id) {
          yield put(
            setCart({
              ...cartData,
              phone: actions.data.phone,
              user_phone: actions.data.phone,
              email: actions.data.email,
              user_email: actions.data.email,
            })
          );
        }

        yield put(
          fetchAddressList(
            {
              user_id: verifyResponse?.data?.data?.user_id,
              store_id: storeData.store_id,
            },
            (addressData) => {
              // Check if its comming from the pickup cta (mobile)
              if (localStorage?.getItem('deliveryType') === 'pickup') {
                if (actions.redirection) actions.router.back();
              }
              // Check if its comming from the deliver cta (mobile)
              else if (localStorage?.getItem('deliveryType') === 'deliver') {
                const addressList = addressData.addressList;

                if (addressList && addressList.length > 0) {
                  actions.router.push(
                    getRoute(PATH.DELIVERY_DETAILS, storeData.store_info?.domain)
                  );
                } else {
                  actions.router.push({
                    pathname: getRoute(PATH.ADD_DELIVERY, storeData.store_info?.domain),
                    query: { fromCart: true },
                  });
                }
              }
            }
          )
        );

        // Check if lead gen user
        if (getLeadId()) {
          const lead_data = getLeadData();
          const phoneReg = /(6|7|8|9)\d{9}/;
          const payload = {
            user_phone: userData?.phone,
            id: getLeadId(),
            store_id: storeData?.store_id,
            lead_email_id: !lead_data.match(phoneReg) ? lead_data : '',
            lead_user_phone: lead_data.match(phoneReg) ? lead_data : '',
          };
          yield put(updateCustomerLead(payload));
        }
        yield put(truecallerAbsent());
      }
      actions.callback && actions.callback(true);
    }
  } catch (err) {
    toastNotifyError(err?.data?.message);
    yield put(toggleVerifyOtp());
    actions.callback && actions.callback(false);
    console.log('err:  ', err);
  }
}

// generate otp for email
function* generateOTPforEmail(actions) {
  try {
    const otpResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.AUTH.generateOTPforEmail,
      data: actions.data,
    });
    if (otpResponse && otpResponse.data.status) {
      const storeData = store.getState().storeReducer.store;
      if (actions.mode === 'web') {
        toastNotifySuccess('OTP sent Successfully');
        actions.callback && actions.callback(true);
      } else {
        actions.router.push({
          pathname: getRoute(PATH.VERIFY_OTP, storeData.store_info.domain),
          query: { phone: actions.data },
        });
      }
    }
  } catch (err) {
    if (err.status === 401) actions.callback && actions.callback(false);
    console.log(err);
  }
}
// Verify OTP for Email
function* verifyOTPforEmail(actions) {
  const storeData = store.getState().storeReducer.store;
  const userData = store.getState().userReducer.data;
  const storeId = storeData?.store_id;

  try {
    const verifyResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.AUTH.verifyOTPforEmail(actions.data.email, actions.data.otp, storeId),
      parseToJson: true,
    });
    if (verifyResponse && verifyResponse.data.status) {
      if (actions?.isLoginAttempt) {
        const cartData = store.getState().cartReducer;
        yield put(saveSelectedAddress({}));
        yield put(saveEmail(actions.data.email));
        localStorage?.setItem('auth_token', verifyResponse?.data?.data?.auth_token);

        yield put(
          saveUserDetails({
            fullname: '',
            email: actions.data.email,
            user_id: verifyResponse?.data?.data?.user_id,
          })
        );
        // yield put(pushEventsLog(actions.data.clevertapEventData));
        yield put(setLogin());
        yield put(toggleVerifyOtp());
        if (cartData?.cart_id && cartData?.store_id) {
          yield put(
            setCart({
              ...cartData,
              email: actions.data.email,
              user_email: actions.data.email,
            })
          );
        }

        yield put(
          fetchAddressList(
            {
              user_id: verifyResponse?.data?.data?.user_id,
              store_id: storeData.store_id,
            },
            (addressData) => {
              // Check if its comming from the pickup cta (mobile)
              if (localStorage?.getItem('deliveryType') === 'pickup') {
                if (actions.redirection) actions.router.back();
              }
              // Check if its comming from the deliver cta (mobile)
              else if (localStorage?.getItem('deliveryType') === 'deliver') {
                const addressList = addressData.addressList;

                if (addressList && addressList.length > 0) {
                  actions.router.push(
                    getRoute(PATH.DELIVERY_DETAILS, storeData.store_info?.domain)
                  );
                } else {
                  actions.router.push({
                    pathname: getRoute(PATH.ADD_DELIVERY, storeData.store_info?.domain),
                    query: { fromCart: true },
                  });
                }
              }
            }
          )
        );

        // Check if lead gen user
        if (getLeadId()) {
          const lead_data = getLeadData();
          const phoneReg = /(6|7|8|9)\d{9}/;
          const payload = {
            user_email: userData?.email || userData?.user_email,
            id: getLeadId(),
            store_id: storeData?.store_id,
            lead_email_id: !lead_data.match(phoneReg) ? lead_data : '',
            lead_user_phone: lead_data.match(phoneReg) ? lead_data : '',
          };
          yield put(updateCustomerLead(payload));
        }
        yield put(truecallerAbsent());
      }
      actions.callback && actions.callback(true);
    } else {
      actions.callback && actions.callback(false);
    }
  } catch (err) {
    toastNotifyError(err?.data?.message);
    yield put(toggleVerifyOtp());
    actions.callback && actions.callback(false);
    console.log('err:  ', err);
  }
}

// Login with Gmail
function* loginWithGmailId(actions) {
  const storeData = store.getState().storeReducer.store;
  const userData = store.getState().userReducer.data;
  const storeId = storeData?.store_id;
  try {
    actions.data.store_id = storeId;
    const Response = yield apiCall({
      method: 'POST',
      url: ENDPOINT.AUTH.LOGIN_WITH_GMAIL,
      data: actions.data,
      parseToJson: true,
    });
    if (Response && Response.data.status) {
      if (actions?.isLoginAttempt) {
        const cartData = store.getState().cartReducer;
        yield put(saveSelectedAddress({}));
        yield put(saveEmail(actions.data.email));
        localStorage?.setItem('auth_token', Response?.data?.data?.auth_token);

        yield put(
          saveUserDetails({
            fullname: '',
            email: actions.data.email,
            user_id: Response?.data?.data?.user_id,
          })
        );
        // yield put(pushEventsLog(actions.data.clevertapEventData));
        yield put(setLogin());
        if (cartData?.cart_id && cartData?.store_id) {
          yield put(
            setCart({
              ...cartData,
              email: actions.data.email,
              user_email: actions.data.email,
            })
          );
        }

        yield put(
          fetchAddressList(
            {
              user_id: Response?.data?.data?.user_id,
              store_id: storeData.store_id,
            },
            (addressData) => {
              // Check if its comming from the pickup cta (mobile)
              if (localStorage?.getItem('deliveryType') === 'pickup') {
                if (actions.redirection) actions.router.back();
              }
              // Check if its comming from the deliver cta (mobile)
              else if (localStorage?.getItem('deliveryType') === 'deliver') {
                const addressList = addressData.addressList;

                if (addressList && addressList.length > 0) {
                  actions.router.push(
                    getRoute(PATH.DELIVERY_DETAILS, storeData.store_info?.domain)
                  );
                } else {
                  actions.router.push({
                    pathname: getRoute(PATH.ADD_DELIVERY, storeData.store_info?.domain),
                    query: { fromCart: true },
                  });
                }
              }
            }
          )
        );

        // Check if lead gen user
        if (getLeadId()) {
          const lead_data = getLeadData();
          const phoneReg = /(6|7|8|9)\d{9}/;
          const payload = {
            user_email: userData?.email || userData?.user_email,
            id: getLeadId(),
            store_id: storeData?.store_id,
            lead_email_id: !lead_data.match(phoneReg) ? lead_data : '',
            lead_user_phone: lead_data.match(phoneReg) ? lead_data : '',
          };
          yield put(updateCustomerLead(payload));
        }
        yield put(truecallerAbsent());
      }
      actions.callback && actions.callback(true);
    }
  } catch (err) {
    toastNotifyError(err?.data?.message);
    yield put(toggleVerifyOtp());
    actions.callback && actions.callback(false);
    console.log(err);
  }
}

// Authenticate User
function* authenticateUserDetails(actions) {
  try {
    const authResponse = yield apiCall({
      method: 'POST',
      data: actions.data,
      url: ENDPOINT.AUTH.AUTHENTICATE_USER,
      parseToJson: true,
    });
    if (authResponse && authResponse.data.status) {
      const storeData = store.getState().storeReducer.store;
      yield put(saveUserDetails(authResponse.data?.data?.user));
      yield put(setLogin());
      yield put(
        fetchAddressList({
          user_id: authResponse.data?.data?.user.user_id,
          store_id: storeData.store_id,
        })
      );
    }
  } catch (err) {
    localStorage.removeItem('auth_token');
    // toastNotifyError(err.data.message || "Authentication failed..");
    // const storeData = store.getState().storeReducer.store;
    // router.push(`/${storeData.store_info.domain.split('-')[1]}/${store.getState().storeReducer.store.store_id}`);
  }
}

// Get User Address
function* getUserAddress(actions) {
  try {
    const addressResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.AUTH.getUserAddressList(actions.data.user_id, actions.data.store_id),
      parseToJson: true,
    });
    if (addressResponse && addressResponse.data.status) {
      yield put(
        saveAddressList({
          addressList: addressResponse?.data?.data?.addresses,
          defaultAddress: addressResponse?.data?.data?.default_address,
        })
      );
      const userData = store.getState().userReducer;
      if (!userData?.isLogin) {
        yield put(saveSelectedAddress(userData?.addressList?.[0]));
      }
      actions.callback && actions.callback(addressResponse?.data?.data);
    }
  } catch (err) {
    // toastNotifyError(err);
    console.log(err);
  }
}

// Save Address
function* saveAddress(actions) {
  const payload = setModifiedDeliveryInfo({ delivery_info: actions.data });
  try {
    const addressResponse = yield apiCall({
      method: 'POST',
      data: payload,
      url: ENDPOINT.AUTH.CREATE_USER_ADDRESS,
    });
    if (addressResponse && addressResponse.data.status) {
      const userData = store.getState().userReducer;
      const storeData = store.getState().storeReducer.store;
      yield put(
        fetchAddressList({
          user_id: userData.data.user_id,
          store_id: storeData.store_id,
        })
      );
      actions?.callback && actions.callback(addressResponse.data.status);
    }
  } catch (err) {
    err?.data?.message && toastNotifyError(err.data.message);
    actions?.callback && actions.callback(false);
    console.log(err);
  }
}

function* authTruecallerDetails(actions) {
  const storeId = store.getState().storeReducer.store.store_id;
  try {
    const truecallerDetails = yield apiCall({
      method: 'GET',
      url: ENDPOINT.AUTH.getTruecallerDetails(actions.data, storeId),
      parseToJson: true,
    });
    if (truecallerDetails && truecallerDetails.data.status) {
      const data = {
        auth_token: truecallerDetails.data.data.auth_token,
        service: '',
      };
      setAuthToken(truecallerDetails.data.data.auth_token);
      yield put(authenticateUser(data));
      yield put(saveSelectedAddress({}));
      yield put(setLogin());
      toastNotifySuccess('Logged in');

      yield put(truecallerAbsent());
      yield put(toggleVerifyOtp());
    }
  } catch (err) {}
}

export default function* root() {
  yield takeEvery(GENERATE_OTP, generateOTP);
  yield takeEvery(VERIFY_OTP, verifyOTP);
  yield takeEvery(AUTHENTICATE_USER, authenticateUserDetails);
  yield takeEvery(FETCH_ADDRESS_LIST, getUserAddress);
  yield takeEvery(SAVE_ADDRESS, saveAddress);
  yield takeEvery(AUTH_TRUECALLER_DETAILS, authTruecallerDetails);
  yield takeEvery(GENERATE_OTP_FOR_EMAIL, generateOTPforEmail);
  yield takeEvery(VERIFY_OTP_FOR_EMAIL, verifyOTPforEmail);
  yield takeEvery(LOGIN_WITH_GMAIL, loginWithGmailId);
}
