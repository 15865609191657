'use client';

import { useEffect } from 'react';
import LocalStorageHelper from '@/utils/LocalStorageHelper';
import { useRouter } from 'next/router';

export function useReduxStorageInvalidation() {
  const router = useRouter();
  const pathToExecuteOn = router.pathname === '/';

  useEffect(() => {
    if (pathToExecuteOn) {
      const appOpenTime = LocalStorageHelper.get('appOpenTime');
      if (!appOpenTime) {
        LocalStorageHelper.add('appOpenTime', Date.now());
      } else {
        const currentTime = Date.now();
        // Time diff in hours
        const timeDiff = Math.floor((currentTime - appOpenTime) / (1000 * 60 * 60));
        if (timeDiff >= 2) {
          // remove keys from local storage of reducer
          const reducerState = LocalStorageHelper.get('stateReducer');
          for (const key in reducerState) {
            if (key) {
              if (['storePolicyReducer', 'promoReducer'].includes(key)) {
                delete reducerState[key];
              }
              LocalStorageHelper.add('stateReducer', reducerState);
            }
          }
        }
      }
    }
  }, []);
  return null;
}
