import { StoreTheme } from '@/redux/reducers/store';
import { FEATURE_LOCKS_INITIAL_LIST, INVALID_DOMAIN_NAMES } from '@/utils/constants';
import axios from 'axios';
import { ENDPOINT } from '../config/endpoints';
import apiCall from '../services/api';

function checkForInvalidDomainName(storename) {
  if (
    INVALID_DOMAIN_NAMES.includes(storename) ||
    storename.includes(INVALID_DOMAIN_NAMES[2])
  )
    return true;
  return false;
}

/**
 * Function for getting store Info by subdomain
 */
export async function fetchStoreInfo(
  storename: string,
  isCustomDomain: boolean,
  context = null
) {
  if (checkForInvalidDomainName(storename)) {
    return { error: 'Invalid Domain Names' };
  }
  try {
    const domain = isCustomDomain ? storename : 'd-' + storename;
    const response = await axios.get(ENDPOINT.STORE.getStoreBySubDomain(domain), {
      headers: {
        'Content-Type': 'application/json',
        secret: '938923c72331801ab55687160a048d5b',
        app_os: 'cfe',
        domain: context ? `${context?.req?.headers?.host}${context?.resolvedUrl}` : ``,
      },
    });
    const data = response.data as any; // { store: StoreDetails };
    return { error: null, data };
  } catch (error) {
    return { error };
  }
}

/**
 * Function for getting theme object
 * by theme ID
 * @param {number} id
 * @param {number} storeId
 * @return {object}
 */
export async function getThemeById(id, storeId) {
  try {
    const response = await axios.get(ENDPOINT.STORE.getThemeById(id, storeId), {
      headers: {
        app_os: 'cfe',
      },
    });
    return { error: null, data: response.data as { status: number; theme: StoreTheme } };
  } catch (error) {
    return { error };
  }
}
/**
 * this function fetches dummy categories and associated products to show in theme preview
 * @param tagId
 * @returns {object}
 */
export async function getDummyProductsByThemeTagId(tagId) {
  try {
    const response = await axios.get(ENDPOINT.STORE.getDummyProductsByThemeTagId(tagId), {
      headers: {
        app_os: 'cfe',
      },
    });

    return { error: null, data: response?.data };
  } catch (error) {
    return { error };
  }
}

export async function getFeatureLocks(storeId) {
  try {
    if (!storeId) {
      return { error:'Invalid store id', data: FEATURE_LOCKS_INITIAL_LIST };
    }
    const response = await axios.get(ENDPOINT.STORE.getFeatureLocks(storeId), {
      headers: {
        app_os: 'cfe',
      },
    });
    return { error: null, data: JSON.parse(response?.data?.data) };
  } catch (error) {
    return { error, data: FEATURE_LOCKS_INITIAL_LIST };
  }
}

export async function fetchAdvSeoDetails(typeDetails) {
  const { storeId, type, typeId } = typeDetails;
  const value = parseInt(typeId);
  try {
    if (storeId && type && typeId && Number.isInteger(value) && typeId != undefined) {
      const response = await axios.get(
        ENDPOINT.SEO.getAdvanceCustomSeoDetails(storeId, type, typeId),
        {
          headers: {
            app_os: 'cfe',
          },
        }
      );
      return { error: null, data: response?.data };
    } else {
      return { error: 'Invalid Input', data: null };
    }
  } catch (error) {
    return { error };
  }
}

export async function fetchJsonPageWidgetResponse(storeInfo) {
  let widgetData = {};

  const jsonPageWidgetData = storeInfo?.theme?.components?.Body.find(
    (component) => component.name === 'JsonPageWidget'
  );

  const jsonPageWidgetId = jsonPageWidgetData?.widget_id;

  if (!jsonPageWidgetId) {
    return {
      error: true,
      data: {},
    };
  }

  try {
    const response = await axios.get(
      ENDPOINT.ADDITIONAL_PAGES.getStaticWidgetDetails(jsonPageWidgetId),
      {
        headers: {
          app_os: 'cfe',
        },
      }
    );

    const data = JSON.parse(response?.data?.data);
    const parsedTemplateData = JSON.parse(data?.template);
    widgetData = parsedTemplateData;

    return {
      error: false,
      data: widgetData,
    };
  } catch (error) {
    return {
      error: true,
      data: {},
    };
  }
}

export async function getCouponList(storeId: number) {
  try {
    const pageResponse = await apiCall({
      method: 'GET',
      url: ENDPOINT.PROMO.getCouponsListByStoreId(storeId),
      // parseToJson: true,
    });

    if (pageResponse && pageResponse.data.status) {
      return JSON.parse(pageResponse.data.data);
    }
  } catch (error) {
    return { error };
  }
}
