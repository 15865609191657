export const loadState = () => {
  try {
    const serializedState = localStorage?.getItem('stateReducer');
    if (serializedState === null) {
      return undefined;
    }
    // return JSON.parse(serializedState)[window.storeinfo.store_info.domain];
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    // const serializedState = JSON.stringify({ [window.storeinfo.store_info.domain] :  state });
    const serializedState = JSON.stringify(state);

    localStorage?.setItem('stateReducer', serializedState);
  } catch {
    // ignore write errors
  }
};
