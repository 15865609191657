import { put, takeEvery } from 'redux-saga/effects';
import apiCall from '../../services/api';
import { ENDPOINT } from 'src/config/endpoints';
import { setExitGatingData } from '../actions';
import { GET_EXIT_GATING_DATA, UPLOAD_MEDIA_TO_S3 } from '../actionTypes';
import { toastNotifyError } from '@/components/Common/Toast';

function* getExitGatingData(actions) {
  try {
    const response = yield apiCall({
      url: ENDPOINT.EXIT_GATING.getExitGatingData(actions.data),
      parseToJson: true,
    });
    if (response?.data?.status) {
      const {
        coupon,
        exit_gating_config: {
          frequency,
          image_url,
          promo_code,
          selected_template,
          status,
          timing,
        },
      } = response.data?.data;

      yield put(
        setExitGatingData({
          status: status,
          selectedTemplate: selected_template,
          imagePath: image_url,
          minUserTimeSpent: timing,
          perDayPopUpLimit: frequency,
          offerSelected: promo_code,
          couponData: coupon,
        })
      );
    }
  } catch (err) {
    console.log(err);
  }
}

function* uploadMediaToS3(actions) {
  try {
    const response = yield apiCall({
      method: 'POST',
      url: ENDPOINT.LOGS.UPLOAD_IMAGE_TO_S3,
      data: actions?.data,
      parseToJson: true,
    });

    if (response?.data?.status) {
      actions?.cb?.(response?.data?.data);
    }
  } catch (err) {
    console.log(err);
    toastNotifyError(err?.message);
  }
}

export default function* root() {
  yield takeEvery(GET_EXIT_GATING_DATA, getExitGatingData);
  yield takeEvery(UPLOAD_MEDIA_TO_S3, uploadMediaToS3);
}
