/**
 * Coupons related Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import {
  applyCoupon,
  saveCouponsList,
  setLoaderState,
  savePaymentOffersList,
  setCart,
} from '../actions';
import {
  FETCH_COUPONS_LIST,
  VERIFY_COUPON,
  FETCH_PAYMENT_OFFERS_LIST,
  VERIFY_PAYMENT_OFFER,
  FETCH_EVALUATED_COUPONS,
} from '../actionTypes';
import { ENDPOINT } from '../../config/endpoints';
import apiCall from '../../services/api';
import { toastNotifyError } from '@/components/Common/Toast';
import store from '../store';

function* fetchCouponsList(actions) {
  try {
    yield put(setLoaderState(true));
    const promoResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.PROMO.getCouponsListByStoreId(actions.data),
      data: actions.data,
      parseToJson: true,
    });
    if (promoResponse && promoResponse.data.status) {
      yield put(saveCouponsList(promoResponse.data.data));
    }
  } catch (error) {
  } finally {
    yield put(setLoaderState(false));
  }
}

function* fetchEvaluatedCouponsList(actions) {
  try {
    yield put(setLoaderState(true));
    const promoResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.PROMO.GET_EVALUATED_ACTIVE_CUSTOMER_COUPONS,
      data: actions.data,
      parseToJson: true,
    });
    if (promoResponse && promoResponse.data.status) {
      yield put(saveCouponsList(promoResponse.data.data));
    }
  } catch (error) {
  } finally {
    yield put(setLoaderState(false));
  }
}

function* verifyCoupon(actions) {
  try {
    const userData = store.getState()?.userReducer?.data;
    actions.data.user_id = userData?.user_id;
    if (userData?.email) {
      actions.data.user_email = userData?.email;
    }
    yield put(setLoaderState(true));
    const promoResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.PROMO.VERIFY_COUPON,
      data: actions.data,
      parseToJson: true,
    });
    if (promoResponse && promoResponse.data.status) {
      if (promoResponse?.data?.data?.reject_reason) {
        actions.callback &&
          actions.callback({
            status: false,
            message: promoResponse?.data?.data?.reject_reason,
          });
      } else {
        yield put(applyCoupon(promoResponse.data.data));
        actions.callback && actions.callback({ status: true });
      }
    }
  } catch (error) {
  } finally {
    yield put(setLoaderState(false));
  }
}

function* fetchPaymentOfferList(actions) {
  try {
    yield put(setLoaderState(true));
    const promoResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.PROMO.getPaymentCouponsListByStoreId(actions.data),
      data: actions.data,
      parseToJson: true,
    });
    if (promoResponse && promoResponse.data.status) {
      yield put(savePaymentOffersList(promoResponse.data.data));
    }
  } catch (error) {
  } finally {
    yield put(setLoaderState(false));
  }
}

function* verifyPaymentOffer(actions) {
  try {
    yield put(setLoaderState(true));
    const promoResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.PROMO.VERIFY_PAYMENT_OFFER,
      data: actions.data,
      parseToJson: true,
    });
    if (promoResponse && promoResponse.data.status) {
      if (promoResponse?.data?.data?.reject_reason) {
        actions.callback &&
          actions.callback({
            status: false,
            message: promoResponse?.data?.data?.reject_reason,
          });
      } else {
        actions.callback &&
          actions.callback({ status: true, data: promoResponse?.data?.data });
        yield put(setCart(promoResponse?.data?.data));
      }
    }
  } catch (error) {
    toastNotifyError("We can't verify your card currently. Please try again later.");
  } finally {
    yield put(setLoaderState(false));
  }
}

export default function* root() {
  yield takeEvery(FETCH_EVALUATED_COUPONS, fetchEvaluatedCouponsList);
  yield takeEvery(FETCH_COUPONS_LIST, fetchCouponsList);
  yield takeEvery(VERIFY_COUPON, verifyCoupon);
  yield takeEvery(FETCH_PAYMENT_OFFERS_LIST, fetchPaymentOfferList);
  yield takeEvery(VERIFY_PAYMENT_OFFER, verifyPaymentOffer);
}
