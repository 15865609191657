import store from '@/redux/store';
import { getRandomString } from '../WidgetDnD/WidgetProperties/JsonFormBuilderArray';
import {
  BUTTON_TYPE,
  HEADER_NAVLINK_TYPE,
  WIDGET_TYPE_LIST,
} from '../WidgetsComponents/CMSConstants';
import { WIDGET_TYPE } from './constant';

export const transformWidgetProps = (props) => {
  if (!props) return {};
  return Object.keys(props).reduce((transformedObject, key) => {
    const newKey = props[key]?.type;
    transformedObject[newKey] = props[key];
    return transformedObject;
  }, {});
};

export const getActiveButtonNameBasedOnType = (type: number) => {
  const res = Object.values(BUTTON_TYPE).find((button) => button.type === type);
  if (res) {
    return res.name;
  } else {
    return 'select one';
  }
};

export const getActiveNavLinkButtonNameBasedOnType = (type: number) => {
  const res = Object.values(HEADER_NAVLINK_TYPE).find((button) => button.type === type);
  if (res) {
    return res.name;
  } else {
    return 'select one';
  }
};

export const getDefaultConfig = (type, bgColor = '', textColor = '') => {
  if (type === 'button') {
    return {
      id: getRandomString(),
      type: 'primary',
      btnText: 'Button Text',
      actions: {
        type: 1,
        handler: '',
        newTab: false,
      },
      btnStyleConfig: null,
      isEditable: true,
    };
  } else if (type === 'bannerButton') {
    return {
      id: getRandomString(),
      type: 'solid',
      text: 'Sample button',
      buttonAnimationConfig:  { classKey: 'widgetBtnShadowWrapper', animationName: 'Add shadow' },
      actions: {
        type: '',
        handler: '',
        newTab: true,
      },
      btnStyleConfig: { minWidth: '160px', backgroundColor: bgColor, textColor: textColor },
      isEditable: true,
      isShow: true,
    };
  } else if (type === 'imageCard') {
    return {
      id: getRandomString(),
      url: '',
      image_href: '',
      height: 1620,
      width: 2880,
      is_desktop: true,
      src: '',
      imgText: '',
    };
  } else if (type === 'emptySingleImageDesktop') {
    return {
      id: getRandomString(),
      url: '',
      image_href: '',
      height: '',
      width: '',
      is_desktop: true,
      src: '',
      imgText: '',
    };
  } else if (type === 'middlebodyGalleryTile') {
    return {
      id: getRandomString(),
      url: '',
      width: '550',
      height: '221',
      is_desktop: true,
      image_href: '',
      imgText: 'ASSET NAME',
    };
  } else if (type === 'mediaVideo') {
    return {
      component_type: 'Body',
      widget_type: '',
      media_url: '',
      media_type: 2,
      media_role: 1,
      media_href: '',
      media_text: '',
      height: 0,
      width: 0,
      is_desktop: true,
      widget_id: '',
    };
  } else if (type === 'headerNavLink') {
    return {
      id: getRandomString(),
      name: 'Button Text',
      isDropdown: false,
      children: [],
      isVisible: true,
      isEditable: true,
      actions: {
        type: 1,
        handler: '',
        newTab: false,
      },
      additionalInfo: {},
    };
  } else if (type === 'middlebodyTabImageGallery') {
    const getRandomId = getRandomString();
    return {
      navText: 'Tab Title',
      id: getRandomString(),
      content: {
        config: null,
        formId: '',
        id: getRandomString(),
        template_image_url: '',
        children: {
          [getRandomId]: {
            id: getRandomId,
            background: {
              type: 'solid',
              default: '#fff',
              content: [],
            },
            foreground: {
              content: [
                {
                  id: getRandomString(),
                  url: '',
                  image_href: '',
                  height: 1620,
                  width: 2880,
                  is_desktop: true,
                  src: '',
                },
              ],
              default: '#fff',
              type: 'image',
              styleConfig: null,
              sliderConfig: null,
            },
            type: 'media',
          },
        },
      },
    };
  } else if (type === 'middlebodyTabImageSlider') {
    const getRandomId = getRandomString();
    return {
      navText: 'Tab Title',
      id: getRandomString(),
      content: {
        config: null,
        formId: '',
        id: getRandomString(),
        template_image_url: '',
        children: {
          [getRandomId]: {
            id: getRandomId,
            background: {
              type: 'solid',
              default: '#fff',
              content: [],
            },
            foreground: {
              content: [
                {
                  id: getRandomString(),
                  url: '',
                  image_href: '',
                  height: 1620,
                  width: 2880,
                  is_desktop: true,
                  src: '',
                  imgText: '',
                },
              ],
              default: '#fff',
              type: 'slider',
              styleConfig: null,
              sliderConfig: {
                slideToShow: {
                  desktop: 1,
                  mobile: 1,
                },
                isAutoplay: true,
                isInfinite: true,
              },
            },
            type: 'media',
          },
        },
      },
    };
  } else if (type === 'middlebodyTabColumn') {
    // const getRandomId = getRandomString();
    return {
      id: getRandomString(),
      navText: 'Tab Title',
      content: {
        id: getRandomString(),
        content: {
          children: {
            mt_middlebody_heading: {
              id: 'mt_middlebody_heading',
              type: 'heading',
              text: '',
              link: '',
              isEditable: true,
            },
            mt_middlebody_description: {
              id: 'mt_middlebody_description',
              type: 'description',
              text: '',
              link: '',
              isEditable: true,
            },
            mt_middlebody_button: {
              id: 'mt_middlebody_button',
              type: 'button',
              content: [],
            },
            mt_middlebody_media: {
              id: 'mt_middlebody_media',
              type: 'media',
              foreground: {
                type: 'image',
                content: [],
                default: '#fff',
                sliderConfig: {
                  slideToShow: {
                    desktop: 1,
                    mobile: 1,
                  },
                  isAutoplay: true,
                  isInfinite: true,
                },
              },
              background: {
                type: 'solid',
                content: [],
                default: '#293032',
              },
            },
          },
          formId: '',
          styleConfig: {
            bannerAspectRatio: {
              desktop: 1259,
              mobile: 950,
            },
            rotationSpeed: 3,
          },
        },
      },
    };
  }
};

export const fetchFormDataFromTemplate = () => {
  const widgetData = store.getState()?.widgetsReducer;
  const formComponentData = widgetData?.themeConfig?.components?.Form;

  if (formComponentData?.length > 0) {
    return formComponentData[0];
  } else {
    return null;
  }
};

export function checkModernTheme(
  isHeaderFooterPropertyActive,
  activeHeaderData,
  activeFooterData
) {
  let widgetName = activeHeaderData.headerName;
  if (isHeaderFooterPropertyActive?.type === WIDGET_TYPE.FOOTER) {
    widgetName = activeFooterData.footerName;
  }
  return widgetName?.toLowerCase().startsWith('modern');
}

export const getHeaderFooterProps = (type, newData, existingWidgetData) => {
  return {
    id: 0,
    sub_type: `Modern${type}`,
    name: newData?.name,
    images: null,
    order: 0,
    config: newData?.config,
    widget_id: existingWidgetData?.props?.widget_id,
  };
};

export const getWidgetType = (type: string) => {
  switch (type) {
    case WIDGET_TYPE.HEADER:
      return WIDGET_TYPE_LIST.WIDGET_TYPE_HEADER;
    case WIDGET_TYPE.MODERN_HEADER:
      return WIDGET_TYPE_LIST.WIDGET_TYPE_MODERN_HEADER;
    case WIDGET_TYPE.FOOTER:
      return WIDGET_TYPE_LIST.WIDGET_TYPE_FOOTER;
    case WIDGET_TYPE.MODERN_FOOTER:
      return WIDGET_TYPE_LIST.WIDGET_TYPE_MODERN_FOOTER;
    default:
      return 0;
  }
};

/**
 * This function will convert array to object using key
 * @param {*} inputArr
 */
export function convertArrayToObject(inputArr: any, uniqueKey = 'mid') {
  if (inputArr?.constructor !== Array) return {};
  const lowerCase = (key: string) => key && key.toLocaleLowerCase();
  return inputArr.reduce(
    (acc, cur) => ({ ...acc, [lowerCase(cur[uniqueKey])]: cur }),
    {}
  );
}
