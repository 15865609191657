import {
  TOGGLE_SEARCHBOX_STATE,
  SET_ATHS_POPUP_VISIBILITY,
  SAVE_ATHS_EVENT,
  SET_LOADER_STATE,
  SET_HEADER_HEIGHT,
  SET_SUBHEADER_HEIGHT,
  SET_HEROBANNER_HEIGHT,
  RESET_HEIGHT,
  SAVE_PRODUCT_LOADER_ID,
  SAVE_DETAILED_ITEM_BY_ID,
  SET_VARIANT_MODEL_VISIBILITY,
  SET_CHOOSE_AND_REPEAT_MODEL_VISIBILITY,
  SET_SELECTED_VARIANT_MODEL_VISIBILITY,
  SET_PAYMENT_MODEL_VISIBILITY,
  GET_ADDITIONAL_PAGE,
  TOGGLE_LAZYPAY_AUTH_MODAL,
  TOGGLE_MOBILE_VIEW,
  TOGGLE_SET_CART_LOADER_STATE,
  SAVE_CREATE_LIST,
  COUPON_BANNER_PRESENT,
  COUPON_BANNER_ABSENT,
  SET_TOGGLE_ADD_DELIVERY_FORM,
  SAVE_HEADER_HEIGHT,
  SET_FILTER_LOADER,
  SAVE_HEADER_REF,
  FETCH_FEATURE_LOCKS,
  SAVE_FEATURE_LOCKS,
  SET_PAYMENT_MODAL_OPTIONS_VISIBILITY,
  SET_GLOBAL_LOADER,
  PREPAID_LIMIT_DISABLE_PAY_NOW,
  HIDE_FILTERS_CATEGORY_AND_COLLECTION,
  TOGGLE_FORM_WIDGET,
  GET_EXIT_GATING_DATA,
  SET_EXIT_GATING_DATA,
  SET_SHOW_INSTALL_APP_OVERLAY,
  UPLOAD_MEDIA_TO_S3,
  TOGGLE_LOGOUT_CONFIRM_MODAL,
  UPDATE_COMMON_REDUCER,
} from './../actionTypes';

export const setSearchBoxState = (data) => {
  return {
    type: TOGGLE_SEARCHBOX_STATE,
    data,
  };
};

export const setATHSPopupVisibility = (data) => {
  return {
    type: SET_ATHS_POPUP_VISIBILITY,
    data,
  };
};

export const saveATHSEvent = (data) => {
  return {
    type: SAVE_ATHS_EVENT,
    data,
  };
};

export const setLoaderState = (data) => {
  return {
    type: SET_LOADER_STATE,
    data,
  };
};

export const setHeaderHeightCalculation = (data) => {
  return {
    type: SET_HEADER_HEIGHT,
    data,
  };
};

export const setSubHeaderHeightCalculation = (data) => {
  return {
    type: SET_SUBHEADER_HEIGHT,
    data,
  };
};

export const getHeroBannerHeightCalculation = () => {
  return {
    type: SET_HEROBANNER_HEIGHT,
  };
};

export const resetAllHeight = () => {
  return {
    type: RESET_HEIGHT,
  };
};

export const saveProductLoaderId = (data) => {
  return {
    type: SAVE_PRODUCT_LOADER_ID,
    data,
  };
};

export const saveDetailedItemById = (data) => {
  return {
    type: SAVE_DETAILED_ITEM_BY_ID,
    data,
  };
};
export const setVariantModalVisibility = (data) => {
  return {
    type: SET_VARIANT_MODEL_VISIBILITY,
    data,
  };
};

export const setChooseAndRepeatModalVisibility = (data) => {
  return {
    type: SET_CHOOSE_AND_REPEAT_MODEL_VISIBILITY,
    data,
  };
};

export const setSelectedVariantModalVisibility = (data) => {
  return {
    type: SET_SELECTED_VARIANT_MODEL_VISIBILITY,
    data,
  };
};

export const setPaymentModalVisiblity = (data) => {
  return {
    type: SET_PAYMENT_MODEL_VISIBILITY,
    data,
  };
};

export const setPaymentModalOptionsVisiblity = (data) => {
  return {
    type: SET_PAYMENT_MODAL_OPTIONS_VISIBILITY,
    data,
  };
};

export const getAddtionalPage = (data) => {
  return {
    type: GET_ADDITIONAL_PAGE,
    data,
  };
};

export const toggleLazyPayAuthModal = (data) => {
  return {
    type: TOGGLE_LAZYPAY_AUTH_MODAL,
    data,
  };
};

export const toggleInaccessibleView = (data) => {
  return {
    type: TOGGLE_MOBILE_VIEW,
    data,
  };
};

export const toggleSetCartLoader = (data) => {
  return {
    type: TOGGLE_SET_CART_LOADER_STATE,
    data,
  };
};

export const saveCreateList = (data) => {
  return {
    type: SAVE_CREATE_LIST,
    data,
  };
};

export const couponBannerPresent = () => {
  return {
    type: COUPON_BANNER_PRESENT,
  };
};

export const couponBannerAbsent = () => {
  return {
    type: COUPON_BANNER_ABSENT,
  };
};

export const setAddDeliveryForm = (data) => {
  return {
    type: SET_TOGGLE_ADD_DELIVERY_FORM,
    data,
  };
};

export const saveHeaderHeight = (data) => {
  return {
    type: SAVE_HEADER_HEIGHT,
    data,
  };
};

export function setFilterLoader(data) {
  return {
    type: SET_FILTER_LOADER,
    data,
  };
}

export function saveHeaderRef(data) {
  return {
    type: SAVE_HEADER_REF,
    data,
  };
}

export const fetchFeatureLocks = (storeId) => ({
  type: FETCH_FEATURE_LOCKS,
  storeId,
});

export const saveFeatureLocks = (data) => ({
  type: SAVE_FEATURE_LOCKS,
  data,
});

export const setGlobalLoader = (data) => ({
  type: SET_GLOBAL_LOADER,
  data,
});

export const hideFiltersCategoryAndCollection = (data) => ({
  type: HIDE_FILTERS_CATEGORY_AND_COLLECTION,
  data,
});

export const setPrepaidLimitDisablePayNow = (data) => ({
  type: PREPAID_LIMIT_DISABLE_PAY_NOW,
  data,
});

export const toggleFormWidget = (data) => ({
  type: TOGGLE_FORM_WIDGET,
  data,
});

export const getExitGatingData = (data) => ({
  type: GET_EXIT_GATING_DATA,
  data,
});

export const setExitGatingData = (data) => ({
  type: SET_EXIT_GATING_DATA,
  data,
});

export const setShowInstallAppOverlay = (data) => ({
  type: SET_SHOW_INSTALL_APP_OVERLAY,
  data,
});

export const uploadMediaToS3 = (data, cb) => ({
  type: UPLOAD_MEDIA_TO_S3,
  data,
  cb,
});

export const toggleLogoutConfirmModal = (data) => ({
  type: TOGGLE_LOGOUT_CONFIRM_MODAL,
  data,
});

export const updateCommonReducer = (data) => ({
  type: UPDATE_COMMON_REDUCER,
  data,
});
